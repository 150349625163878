import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import HeaderShowreel from "../components/HeaderShowreel";
import Footer from "../components/Footer";
import { scroller } from 'react-scroll';
import HMSection from "../components/HMSection";
import AboutSection from "../components/AboutSection";
import RoleSection from "../components/RoleSection";
import NewsSectionHome from "../components/NewsSectionHome";
import ProtocolSection from "../components/ProtocolSection";
import LinksSection from "../components/LinksSection";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  about,
  role,
  protocol,
  footer
}) => {

  return (
    <div>

      {/* Header (inc Nav component - no need for strapline)
      About - Content
      The Role / History - Big Buttons
      County Down section
      Protocol
      News - items of News
      Contact
      Footer */}

      <HeaderShowreel/>

      <HMSection />

      <AboutSection heading={about.title} description={about.description} image={about.image} />

      <RoleSection heading={role.title} description={role.description} />

      <ProtocolSection 
        heading={protocol.title} 
        precedenceHeading={protocol.precedenceHeading} 
        precedenceDescription={protocol.precedenceDescription} 
        precedenceImage={protocol.precedenceImage} 
        addressingHeading={protocol.addressingHeading} 
        addressingDescription={protocol.addressingDescription}
        addressingImage={protocol.addressingImage} 
      />

      <LinksSection />

      <NewsSectionHome heading={"News"} showViewAll={true} />

      <Footer 
        heading={footer.heading} 
        subHeading={footer.subheading} 
        contactEmail={footer.contactEmail} 
        contactPhone={footer.contactPhone} 
        facebookLink={footer.facebookLink} 
        twitterLink={footer.twitterLink} 
        instagramLink={footer.instagramLink}  
      />


      {/* <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        style={{backgroundColor: "#ddd", color:"#222", textShadow:"none", borderTop: "2px solid #bbb"}}
        buttonStyle={{ backgroundColor: "#2c362a", fontWeight: "normal", color:"#fff", marginTop:0, padding: "6px 24px" }}
        cookieName="danny-boy-links-golf">
        This website uses cookies to enhance the user experience.
      </CookieConsent> */}
    
    </div> 
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  courses: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  features: PropTypes.shape({
    feature: PropTypes.array,
  }),
  faqs: PropTypes.shape({
    faq: PropTypes.array,
  }),
};

const IndexPage = ({ location, data }) => {
  const { frontmatter } = data.markdownRemark;

  useEffect(()=> {
    if (location.hash) {
      document.body.classList.remove('mobileNavOpen');
        scroller.scrollTo(location.hash.substring(1), {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart"
        });
    }
}, [location,])

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        about={frontmatter.about}
        role={frontmatter.role}
        protocol={frontmatter.protocol}
        footer={frontmatter.footer}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "development-page" } }) {
      frontmatter {
        title
        heading
        subheading
        description
        mainpitch {
          title
          subtitle
          description
          summaryTitle
          golfText
          hostText
          accommodationText
          transportText
          diningText
          sightsText
          summaryPrice
        } 
        about {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
            }
          }
        }
        role {
          title
          description
        }
        protocol {
          title
          precedenceHeading
          precedenceDescription
          precedenceImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
            }
          }
          addressingHeading
          addressingDescription
          addressingImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
            }
          }
        }
        footer {
          heading
          subheading
          contactEmail
          contactPhone
          facebookLink
          twitterLink
          instagramLink
        }
      }
    }
  }
`;